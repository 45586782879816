<template>
  <div class="order">
    <ShopNavBar title="订单管理" />

    <div class="tabs">
      <van-tabs v-model:active="active" @change="tabsChange">
        <van-tab title="全部"></van-tab>
        <van-tab title="待发货"></van-tab>
        <van-tab title="待收货"></van-tab>
        <van-tab title="已收货"></van-tab>
        <van-tab title="已退款"></van-tab>
      </van-tabs>
    </div>

    <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false"
      offset="10" finished-text="没有更多了" @load="onLoad">
      <div class="order-list">
        <div class="order-item" v-for="(dd, index) in list" :key="index">
          <van-cell :title="dd.number" value="详情" is-link @click="tolink('orderDetails?id=' + dd.id)" />
          <div class="order-goods" v-for="(item, index) in dd.dbShopOrderChild" :key="index">
            <img class="goods-img" :src="getimg(item.img[0])" />
            <div class="order-goods-content">
              <div class="goods-name">{{ item.goodsName }}</div>
              <div class="goods-label">规格：{{ item.goodsModelName }}</div>
              <div class="goods-row">
                <div class="goods-price">￥{{ item.price }}</div>
                <div class="goods-num">x {{ item.num }}</div>
              </div>
            </div>
          </div>
          <div class="order-total">
            共<text>{{ dd.num }}</text>件商品，合计<text>￥{{ dd.amount }}</text>
          </div>
          <div class="order-total q-mt-sm">
            <div class="btn" @click="tuikuan(dd.id)" v-if="dd.tk == 1">同意退款</div>
            <div class="btn disable-btn" v-if="dd.tk > 1">{{ dd.tkName }}</div>
            <div class="btn q-ml-md" @click="tolink('orderDetails?id=' + dd.id)">详情</div>
          </div>
        </div>
      </div>
    </van-list>

    <van-empty description="暂无订单" v-if="list.length == 0" />
  </div>
</template>

<script>
import { Tab, Tabs, Empty, List } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Empty.name]: Empty,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      active: ref(0),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      list: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    getimg(img) {
      if (img != "") {
        return this.$config.send_url + "upload/" + img;
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    // 加载下一页
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    tabsChange() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopOrder/ShopUserGetOrderList",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          orderState: _this.active == 0 ? '' : _this.active
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 100) {
            let data = res.data.data
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            data.forEach(item => {
              item.dbShopOrderChild.forEach(item2 => {
                item2.img = item2.img.split(",")
              })
            })
            _this.list.push(...data)
            // console.log(data)
          }
        }
      )
    },
    tuikuan(oid) {
      let _this = this
      _this.$q.dialog({
        message: "确定要退款吗？",
        cancel: true,
      }).onOk(() => {
        _this.$request.post(
          "api/ShopOrder/ShopUserOrderTuikuan",
          {
            
            userid: _this.us.userid,
            oid
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg
            })
            if(res.data.code == 100) {
              _this.pageIndex = 1
              _this.loading = false
              _this.finished = false
              _this.list = []
              _this.getdata()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.order {
  padding-top: 44px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.tabs {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  z-index: 9;
}

.order-list {
  padding: 10px;
}

.order-item {
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.order-goods {
  padding: 10px;
  display: flex;
  background-color: #fff;
}

.goods-img {
  width: 80px;
  height: 80px;
}

.order-goods-content {
  padding-left: 10px;
  flex: 1;
}

.goods-label {
  color: gray;
  font-size: 13px;
}

.goods-price {
  color: #ff2d2e;
}

.goods-num {
  font-size: 12px;
  color: gray;
}

.goods-row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.order-total {
  padding: 0 10px 10px;
  text-align: right;
}

.order-total text {
  color: #ff2d2e;
}

.down-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  color: rgb(237, 106, 12);
  background-color: rgb(255, 251, 232);
}

.goods-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.order :deep(.van-count-down) {
  margin: 0 4px;
  color: #ff2d2e;
}

.btn {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.disable-btn {
  opacity: .8;
}
</style>